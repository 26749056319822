/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useEffect, useContext, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import Modal from "../../components/Modal/Modal"
import { Menu } from "@headlessui/react"
import { Link } from "gatsby"
import LazyImage from "../../components/LazyImage"
import Skeleton from "../../components/Skeleton"
import { Table, Tr, Td } from "../../components/Table/TableElements"
import BreadCrumb from "../../components/Elements/BreadCrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import Pagination from "../../components/Pagination"
import ManageUserTable from "../../components/Table/ManageUserTable"
import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import StateContext from "../../context/state/StateContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import CircleAvatar from "../../components/Elements/CircleAvatar"
import { getMyAccount, getSubUserListing } from "../../helpers/DrupalHelper"
import ManageAccountForm from "../../components/Forms/ManageAccountForm"
import Button from "../../components/Elements/Button"
import NotificationContext from "../../context/Notification/NotificationContext"
import { StarIcon } from "@heroicons/react/outline"
import FloatingInput from "../../components/Elements/Form/FloatingInput"
import PageSubtitle from "../../components/PageSubtitle"
import QRCode from "react-qr-code"
const MenuSection = props => {
  return (
    <div className={`mb-6 ${props.className && props.className}`}>
      <MenuHead title={props.title} link={props.link} />
      {props.children}
    </div>
  )
}
const MenuHead = props => {
  const { title, link } = props
  return (
    <div className="pb-2 w-full">
      <span
        className=""
        css={css`
          text-transform: uppercase;
          color: #717171;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: 0.02rem;
        `}
      >
        <Link to={link}>{title}</Link>
      </span>
    </div>
  )
}
const Meta = props => {
  const { green, red } = props
  return (
    <span
      className={`text-reg-12 text-[#717171] !leading-3 bg-[rgba(0,0,0,.04)] rounded-[6px] h-[20px] inline-flex items-center px-2 ${
        green && `!text-white !bg-[#009d4f]`
      } ${red && `!text-white !bg-[#FE1B1A]`}`}
    >
      {props.children}
    </span>
  )
}

function MyAccount() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  dashboardTrail.push({ text: "Manage Users", link: "#" })
  const { toggleNotification } = useContext(NotificationContext)
  const { state } = useContext(StateContext)
  const { authentication, userType } = useContext(AuthenticationContext)
  const {
    companies,
    primary_company,
    brands,
    retailers,
    referral_code,
    profile_picture,
  } = authentication.currentUserData
  const brand_company = companies?.filter(item => item.type === "brand")
  const retailer_company = companies?.filter(item => item.type === "retailer")
  const [data, setData] = useState(null)
  const onSave = (data, v) => {
    if (v === "edit") {
      toggleNotification({ content: "Changes are made Sucessfully" })
      setData(data)
    } else {
      toggleNotification({ content: data + " Failed", error: true })
    }
  }
  useEffect(async () => {
    // console.log(state)
    const data = await getMyAccount(state.token)
    // console.log(data)
    setData({ ...data, profile_picture })
  }, [profile_picture])
  const active = false
  return (
    <LayoutDashborad metaTitle="My Account" trail={dashboardTrail}>
      <div
        className={`lg:w-1/2 lg:pr-8 ${
          userType.brandManager && "lg:mt-[10px] md:mt-[10px]"
        }${userType.brandEditor && "lg:mt-[105px] md:mt-[130px]"} ${
          !userType.brandEditor && "lg:mt-[80px] md:mt-[80px]"
        } `}
      >
        <PageSubtitle
          title="View and edit your personal account profile."
          marginTop="20px"
        />
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-2/3 md:pr-[30px]">
          {data && (
            <ManageAccountForm
              token={state.token}
              data={data}
              onSave={onSave}
            />
          )}
        </div>
        <div className="pt-[30px] md:pt-0 md:w-1/3">
          {/* {(userType.brandEditor === true ||
            userType.brandManager === true) && (
            <div className="mb-[30px] md:mb-10">
              <SectionCard title="Referral Code">
                <div className="">
                  <FloatingInput
                    label="Referral Code"
                    name="refercode"
                    type="text"
                    disabled
                    description={
                      "Share referral code with others to enjoy exclusive discounts"
                    }
                  />
                  <Button type="submit" red>
                    Copy
                  </Button>
                </div>
              </SectionCard>
            </div>
          )} */}
          {(userType.brandManager === true ||
            userType.brandEditor === true) && (
            <div className="mb-[30px] md:mb-10">
              <SectionCard title="Referral Code">
                <div className="md:flex flex-col gap-5 justify-center items-center">
                  <QRCode
                    value={`https://app.useo2o.com/register?referral-code=${referral_code}`}
                  />
                  <h1 className="text-2xl font-medium">{referral_code}</h1>
                </div>
              </SectionCard>
            </div>
          )}
          <SectionCard title="Associations">
            <div className="">
              {brand_company.length > 0 && (
                <MenuSection title="Brand Companies">
                  <Table flush>
                    {brand_company.map((item, k) => {
                      let user_type = ""
                      if (item.type === "brand")
                        user_type =
                          item.association === "lead" ? "Manager" : "Sales Rep"
                      if (item.type === "retailer")
                        user_type =
                          item.association === "lead" ? "Manager" : "Employee"
                      return (
                        <Tr>
                          <Td>
                            <div className="flex">
                              <div className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[70%]">
                                {item.title}
                              </div>
                              <div className="ml-auto flex gap-2 items-center">
                                {primary_company !== null &&
                                  primary_company === parseInt(item.id) && (
                                    <span className="mr-1 flex">
                                      <Meta green>
                                        <StarIcon className="h-3 w-3 mr-1" />{" "}
                                        Primary
                                      </Meta>
                                    </span>
                                  )}
                                {item.status === "approved" ? (
                                  <Meta green>
                                    <span className="capitalize">
                                      {item.status}
                                    </span>
                                  </Meta>
                                ) : item.status === "denied" ? (
                                  <Meta red>
                                    <span className="capitalize">
                                      {item.status}
                                    </span>
                                  </Meta>
                                ) : (
                                  <Meta>
                                    <span className="capitalize">
                                      {item.status}
                                    </span>
                                  </Meta>
                                )}
                                <Meta>{user_type}</Meta>
                              </div>
                            </div>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Table>
                </MenuSection>
              )}
              {retailer_company.length > 0 && (
                <MenuSection title="Retail Companies">
                  <Table flush>
                    {retailer_company.map((item, k) => {
                      let user_type = ""
                      if (item.type === "brand")
                        user_type =
                          item.association === "lead" ? "Manager" : "Sales Rep"
                      if (item.type === "retailer")
                        user_type =
                          item.association === "lead" ? "Manager" : "Employee"
                      return (
                        <Tr>
                          <Td>
                            <div className="flex">
                              <div className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[70%]">
                                {item.title}
                              </div>
                              <div className="ml-auto flex gap-2 items-center">
                                {primary_company !== null &&
                                  primary_company === parseInt(item.id) && (
                                    <span className="mr-1 flex">
                                      <Meta green>
                                        <StarIcon className="h-3 w-3 mr-1" />{" "}
                                        Primary
                                      </Meta>
                                    </span>
                                  )}
                                {item.status === "approved" ? (
                                  <Meta green>
                                    <span className="capitalize">
                                      {item.status}
                                    </span>
                                  </Meta>
                                ) : item.status === "denied" ? (
                                  <Meta red>
                                    <span className="capitalize">
                                      {item.status}
                                    </span>
                                  </Meta>
                                ) : (
                                  item.status && (
                                    <Meta>
                                      <span className="capitalize">
                                        {item.status}
                                      </span>
                                    </Meta>
                                  )
                                )}
                                <Meta>{user_type}</Meta>
                              </div>
                            </div>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Table>
                </MenuSection>
              )}
              {
                // {brands.length > 0 && (
                //   <MenuSection title="Brands" link={"/dashboard/my-brands"}>
                //     <Table flush>
                //       {brands.map((item, k) => {
                //         return (
                //           <Tr>
                //             <Td>
                //               <div className="flex items-center">
                //                 <CircleAvatar
                //                   image={item.logo}
                //                   xs
                //                   border
                //                   shadowNone
                //                 />
                //                 <span className="pl-2">{item.title}</span>
                //               </div>
                //             </Td>
                //             <Td className="float-right">
                //               <Meta>
                //                 {item.association === "lead"
                //                   ? "Manager"
                //                   : "Employee"}
                //               </Meta>
                //             </Td>
                //           </Tr>
                //         )
                //       })}
                //     </Table>
                //   </MenuSection>
                // )}
              }
              {
                // {retailers.length > 0 && (
                //   <MenuSection title="Retailers" link={"/dashboard/my-retailers"}>
                //     <Table flush>
                //       {retailers.map((item, k) => {
                //         return (
                //           <Tr>
                //             <Td>
                //               <div className="flex">
                //                 <div className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[70%]">
                //                   {item.title}
                //                 </div>
                //                 <div className="ml-auto">
                //                   <Meta>
                //                     {item.association === "lead"
                //                       ? "Manager"
                //                       : "Employee"}
                //                   </Meta>
                //                 </div>
                //               </div>
                //               {/*<div>{item.title}</div>
                //             </Td>
                //             <Td className="float-right">
                //               <Meta>
                //                 {item.association === "lead"
                //                   ? "Manager"
                //                   : "Employee"}
                //               </Meta>*/}
                //             </Td>
                //           </Tr>
                //         )
                //       })}
                //     </Table>
                //   </MenuSection>
                // )}
              }
            </div>
          </SectionCard>
        </div>
      </div>
    </LayoutDashborad>
  )
}
export default MyAccount
