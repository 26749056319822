/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
const PageSubtitle = props => {
  return (
    <h2
      css={css`
        &&& {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          margin: 0 0 15px;
          margin-top: ${props.marginTop};
          text-align: left;
          letter-spacing: 0;
          font-family: "Jost", sans-serif;
        }
      `}
    >
      {props.title}
    </h2>
  )
}
export default PageSubtitle
